import getTranslationFromKey from './formErrorMapping';

export default {
  props: {
    errorData: {
      type: Object,
    },
  },

  data() {
    return {
      errors: [],
    };
  },

  mounted() {
    this.errors = getTranslationFromKey(this.errorData);
  },
};
