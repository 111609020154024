import ActionButton from 'Components/buttons/actionButton/ActionButton.vue';
import IconComponent from 'Components/icons/iconComponent/IconComponent.vue';
import FormComponent from 'Components/form/formComponent/FormComponent.vue';

export default {
  components: {
    ActionButton,
    FormComponent,
    IconComponent,
  },

  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      validator(value) {
        return ['small', 'large'].indexOf(value) !== -1;
      },
      default: 'large',
    },
    isDisplayed: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      bodyElement: document.querySelector('body'),
    };
  },

  created() {
    document.addEventListener('keydown', this.checkEscKeydown, true);
  },

  beforeUnmount() {
    document.removeEventListener('keydown', this.checkEscKeydown, true);
  },

  computed: {
    isFooterPresent() {
      return !!this.$slots.footer;
    },

    isBodyPresent() {
      return !!this.$slots.body;
    },
  },

  methods: {
    checkEscKeydown(event) {
      const key = event.key || event.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.$emit('hideModal');
      }
    },

    hideModal() {
      this.$emit('hideModal');
    },
  },
};
