<template>
  <form
    ref="formComponent"
    class="form-component"
    @submit.stop.prevent="(e) => beforeSubmit(e || {})"
  >
    <FormError
      v-if="errorData"
      :error-data="errorData"
    />
    <slot />
  </form>
</template>

<script src="./formComponent.js"></script>

<style lang="scss">
  .form-component {
    .form-error {
      margin-bottom: 10px;
    }
  }
</style>
