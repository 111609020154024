import scrollToRef from '@/helpers/scrollToRef';
import FormError from '../formError/FormError.vue';

export default {
  components: {
    FormError,
  },
  props: {
    validation: {
      type: Object,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    errorData: {
      type: Object,
    },
  },

  watch: {
    errorData: {
      deep: true,
      handler() {
        if (!this.errorData) return;
        this.scrollToError();
      },
    },
  },

  methods: {
    async beforeSubmit(event) {
      if (this.validation) {
        await this.validation.$touch();
        const { $error, $invalid } = this.validation;

        if ($error || $invalid) {
          this.scrollToError();
        } else {
          this.onSubmit(event.submitter?.value);
        }
      } else {
        this.onSubmit(event.submitter?.value);
      }
    },
    scrollToError() {
      const { formComponent } = this.$refs;
      const refToScroll = this.errorData
        ? formComponent : formComponent.querySelector('.form-group--is-invalid');

      if (!refToScroll) return;
      scrollToRef(refToScroll);
    },
  },
};
