<template>
  <div
    class="form-error"
    role="alert"
    data-test="error-notification"
  >
    <span
      v-for="(error, index) in errors"
      :key="index"
      v-sanitize-html="error"
      class="form-error__message"
    />
  </div>
</template>

<script src="./formError.js"></script>

<style lang="scss">
  .form-error {
    display: flex;
    padding: 10px 10px;
    flex-direction: column;
    border: 1px solid $critical-60;
  }

  .form-error__icon {
    margin-right: 10px;
  }

  .form-error__message {
    align-self: flex-start;
    color: $critical-60;

    a {
      color: $neutral-80;
    }
  }
</style>
